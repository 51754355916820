// src/components/Layout/Header/Header.tsx
import React, { useState } from 'react';
import '../../styles/Header.css';
import logo from '../../assets/logo_black.png';


import { WalletActionButton } from '@tronweb3/tronwallet-adapter-react-ui';

interface HeaderProps {
  isConnected?: boolean;
  walletAddress?: string;
  onConnect?: () => void;
  onDisconnect?: () => void;
}



const Header: React.FC<HeaderProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <a href="/" className="logo">
            <img src={logo} alt="Logo" className="logo-image" />
            <span className="logo-text">OnChainVision</span>
          </a>
        </div>

        <button className="mobile-menu-button" onClick={toggleMenu}>
          <span className={`hamburger ${isMenuOpen ? 'open' : ''}`}></span>
        </button>

        <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
        <a href="/transfer" className="nav-link">Transfer</a>
          <a href="/swap" className="nav-link">Swap</a>
          <a href="https://play.google.com/store/apps/details?id=com.onchainvision.onchainvision" className="nav-link"> Mobile</a>
          
          <div className="wallet-section">
            <WalletActionButton></WalletActionButton>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
