// src/components/tokens/TokenTransfer.tsx
import React, { useState, useEffect } from 'react';
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Token } from '../../types/token';
import defaultTokenImage from '../../assets/default-token.png';
import '../../styles/TokenTransfer.css';
import { tronWeb } from '../../tronweb';
import abi from '../abi/trc20.json';
import toast from 'react-hot-toast';
import { Account, AccountToken } from '../../tronApi/tronTypes';

import { getExplorerTxUrl } from '../../utils/explorer';
import { checkTransactionWithTimeout } from '../../utils/transactions';
import { convertFromUnit, convertToUnit } from '../../utils/balance';

import { delegateBandwidth, delegateEnergy, undelegateBandwidth, undelegateEnergy } from '../../utils/delegate';
import { tronClient } from '../../tronApi/tronClient';


interface TokenTransferProps {
  account: Account | null;
  tokens: Token[];
  defaultTokenAddress?: string;
}

const TokenTransfer: React.FC<TokenTransferProps> = ({
  account,
  tokens,
  defaultTokenAddress,
}) => {
  const [selectedToken, setSelectedToken] = useState<Token | null>(null);
  const [amount, setAmount] = useState('');
  const [receiver, setReceiver] = useState('');
  const [txId, setTxId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { signMessage, signTransaction, address } = useWallet();

  useEffect(() => {
    if (defaultTokenAddress && tokens.length > 0) {
      const defaultToken = tokens.find(token => token.address === defaultTokenAddress);
      setSelectedToken(defaultToken || tokens[0]);
      setAmount('');
      setReceiver('');
    } else if (tokens.length > 0) {
      setSelectedToken(tokens[0]);
      setAmount('');
      setReceiver('');
    }
  }, [tokens, defaultTokenAddress]);

  const handleMaxAmount = () => {
    if (selectedToken) {
      setAmount(selectedToken.balance);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedToken || !amount || !receiver) return;

    setIsLoading(true);
    try {
      await onTransfer(selectedToken, amount, receiver);
      setAmount('');
      setReceiver('');
    } catch (error) {
      console.error('Transfer failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onTransfer = async (tokenAddress: Token, amount: string, receiver: string) => {
    if (!selectedToken || !amount || !receiver || !address) {
      throw new Error('Missing required parameters');
    }

    try {
      // Convert amount to contract units
      const amountInSun = convertToUnit(amount, selectedToken.decimals);
      console.log('Amount in Sun:', amountInSun);

      // Validate address
      const isValidAddress = tronWeb.isAddress(receiver);
      if (!isValidAddress) {
        toast.error('Invalid receiver address');
        throw new Error('Invalid receiver address');
      }

      // Convert addresses to hex format
      const contractAddress = tronWeb.address.toHex(selectedToken.address);
      const receiverHex = tronWeb.address.toHex(receiver);
      const senderHex = tronWeb.address.toHex(address);

      // Prepare parameters for the transfer function
      const parameter = [{
        type: 'address',
        value: receiverHex
      }, {
        type: 'uint256',
        value: amountInSun.toString()
      }];

      // Transaction options
      const options = {
        feeLimit: 100000000,
        callValue: 0
      };

      // Create the transaction
      const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
        contractAddress,
        'transfer(address,uint256)',
        options,
        parameter,
        senderHex
      );
      // Get started time in milliseconds
      const startedTime = new Date().getTime();

      if (!transaction.result || !transaction.result.result) {
        toast.error('Transaction creation failed');
        throw new Error('Transaction creation failed');
      }

      console.log('Transaction created:', transaction);

      //Get the account resource
      tronClient.refreshAccountEnergy(account);
      const init_bandwidth = account.bandwidth - account.bandwidthUsed;
      const init_energy = account.energy - account.energyUsed;
      console.log('Initial bandwidth:', init_bandwidth);
      console.log('Initial energy:', init_energy);

      const buffer_energy = 1000;
      const buffer_bandwidth = 100;

      // Sign the transaction
      const signedTransaction = await signTransaction(transaction.transaction);
      console.log('Signed transaction:', signedTransaction);

      // Delegate resources to the transaction
      const resultDelegateBandiwdth = await delegateBandwidth(senderHex, 100000000);
      console.log('Delegate Energy for Transfer Token Result:', resultDelegateBandiwdth);
      if (!resultDelegateBandiwdth) {
        toast.error('Failed to delegate bandiwth');
        throw new Error('Failed to delegate resources');
      }

      const resultDelegateEnergy = await delegateEnergy(senderHex, 10000000000);
      console.log('Delegate Energy for Transfer Token Result:', resultDelegateEnergy);
      if (!resultDelegateEnergy) {
        toast.error('Failed to delegate energy');
        throw new Error('Failed to delegate energy');
      }

      // A block is produce every 3 seconds, so wait at least 4 seconds before broadcasting the transaction
      await new Promise(resolve => setTimeout(resolve, 4000));

      // Refresh the account energy up until the transaction is confirmed
      var current_bandwidth = account.bandwidth - account.bandwidthUsed;
      var current_energy = account.energy - account.energyUsed;
      while (
        current_bandwidth <= (init_bandwidth + buffer_bandwidth) &&
        current_bandwidth >= (init_bandwidth - buffer_bandwidth) &&
        current_energy <= (init_energy + buffer_energy) &&
        current_energy >= (init_energy - buffer_energy)) {

        // wait half a second before refreshing the account energy
        await new Promise(resolve => setTimeout(resolve, 500));

        await tronClient.refreshAccountEnergy(account);
        current_bandwidth = account.bandwidth - account.bandwidthUsed;
        current_energy = account.energy - account.energyUsed;

        console.log('Refreshing account energy...init_bandwidth: ', init_bandwidth,
          ' init_energy:', init_energy,
          ' bandwidth:', current_bandwidth,
          ' energy:', current_energy);
      }

      // Resources have been allocated to the transaction
      const before_transfer_bandwidth = current_bandwidth;
      const before_transfer_energy = current_energy;
      console.log('Before transfer bandwidth:', before_transfer_bandwidth);
      console.log('Before transfer energy:', before_transfer_energy);

      // If from started time to now is more than 60 seconds, then return with error
      if (new Date().getTime() - startedTime > 60000) {
        toast.error('Transaction timed out');
        throw new Error('Transaction timed out');
      }

      // Broadcast the transaction
      const result = await tronWeb.trx.sendRawTransaction(signedTransaction);
      console.log('Transaction result:', result);
      
      // Get the transaction from tronweb
      const tx = await tronWeb.trx.getTransaction(result.txid);
      console.log('Transaction from tronweb:', tx);
      if (!result.result) {
        toast.error('Transaction broadcast failed');
        throw new Error('Transaction broadcast failed');
      }
      // Wait for the transaction to be confirmed
      await checkTransactionWithTimeout(result.txid || result.transaction.txID);

      // Refresh the account energy up until the transaction is confirmed - less resource consumption
      var current_bandwidth_2 = account.bandwidth - account.bandwidthUsed;
      var current_energy_2 = account.energy - account.energyUsed;
      while (
        current_bandwidth_2 <= (before_transfer_bandwidth + buffer_bandwidth) &&
        current_bandwidth_2 >= (before_transfer_bandwidth - buffer_bandwidth) &&
        current_energy_2 <= (before_transfer_energy + buffer_energy) &&
        current_energy_2 >= (before_transfer_energy - buffer_energy)) {
        // wait half a second before refreshing the account energy
        await new Promise(resolve => setTimeout(resolve, 500));
        await tronClient.refreshAccountEnergy(account);
        current_bandwidth_2 = account.bandwidth - account.bandwidthUsed;
        current_energy_2 = account.energy - account.energyUsed;
        console.log('Refreshing account energy...before_transfer_bandwidth: ', before_transfer_bandwidth,
          ' before_transfer_energy:', before_transfer_energy,
          ' bandwidth:', current_bandwidth_2,
          ' energy:', current_energy_2);
      }
      const after_transfer_bandwidth = current_bandwidth_2;
      const after_transfer_energy = current_energy_2;
      console.log('After transfer bandwidth:', after_transfer_bandwidth);
      console.log('After transfer energy:', after_transfer_energy);

      // Wait at least 4 seconds before undelegating resources
      await new Promise(resolve => setTimeout(resolve, 4000));

      // Undelegate resources to the transaction
      const resultUnDelegateBandiwdth = await undelegateBandwidth(senderHex, 100000000);
      console.log('Undelegate Bandwidth for Transfer Token Result:', resultUnDelegateBandiwdth);
      if (!resultUnDelegateBandiwdth) {
        toast.error('Failed to delegate bandiwth');
        throw new Error('Failed to delegate resources');
      }

      const resultUnDelegateEnergy = await undelegateEnergy(senderHex, 10000000000);
      console.log('Unelegate energy for Transfer Token Result:', resultUnDelegateEnergy);
      if (!resultUnDelegateEnergy) {
        toast.error('Failed to delegate energy');
        throw new Error('Failed to delegate energy');
      }
      toast.success('Transfer successful!');
      setTxId(result.txid || result.transaction.txID);

      // Refresh the account energy up until the resources are undelegated
      var current_bandwidth_3 = account.bandwidth - account.bandwidthUsed;
      var current_energy_3 = account.energy - account.energyUsed;
      while (
        current_bandwidth_3 > (init_bandwidth + buffer_bandwidth) ||
        current_bandwidth_3 < (init_bandwidth - buffer_bandwidth) ||
        current_energy_3 > (init_energy + buffer_energy) ||
        current_energy_3 < (init_energy - buffer_energy)) {
        // wait half a second before refreshing the account energy
        await new Promise(resolve => setTimeout(resolve, 500));
        await tronClient.refreshAccountEnergy(account);
        current_bandwidth_3 = account.bandwidth - account.bandwidthUsed;
        current_energy_3 = account.energy - account.energyUsed;
        console.log('Refreshing account energy...init_bandwidth: ', init_bandwidth,
          ' before_transfer_energy:', init_energy,
          ' bandwidth:', current_bandwidth_3,
          ' energy:', current_energy_3);
      }
      console.log('After undelegate bandwidth:', current_bandwidth_3);
      console.log('After undelegate energy:', current_energy_3);

      // return result;
    } catch (error) {
      console.error('Error in transfer:', error);
      throw error;
    }
  };


  return (
    <div className="token-transfer-container">
      <Typography variant="h6" gutterBottom>
        Transfer Tokens
      </Typography>

      <form onSubmit={handleSubmit} className="token-transfer-form">
        <FormControl className="form-control">
          <InputLabel id="token-select-label">Select Token</InputLabel>
          <Select
            labelId="token-select-label"
            value={selectedToken?.address || ''}
            label="Select Token"
            onChange={(e) => {
              const token = tokens.find(t => t.address === e.target.value);
              setSelectedToken(token || null);
              setAmount('');
              setReceiver('');
            }}
            className="token-select"
          >
            {tokens.map((token) => (
              <MenuItem key={token.address} value={token.address} className="token-select-item">
                <img
                  src={token.logo || defaultTokenImage}
                  alt={token.symbol}
                  className="token-logo"
                  onError={(e) => {
                    (e.target as HTMLImageElement).src = defaultTokenImage;
                  }}
                />
                <Box className="token-info">
                  <Typography variant="body1">{token.symbol}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Balance: {token.balance}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="form-control">
          <TextField
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
            className="amount-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={handleMaxAmount}
                    size="small"
                    className="max-button"
                  >
                    MAX
                  </Button>
                </InputAdornment>
              ),
            }}
            helperText={selectedToken ? `Balance: ${selectedToken.balance} ${selectedToken.symbol}` : ''}
          />
        </FormControl>

        <FormControl className="form-control">
          <TextField
            label="Receiver Address"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
            placeholder="Enter receiver's address"
            className="address-input"
          />
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          disabled={!selectedToken || !amount || !receiver || isLoading}
          className="submit-button"
        >
          {isLoading ? 'Processing...' : 'Transfer'}
        </Button>
      </form>
      {txId && (
        <div className="transaction-id">
          <Typography variant="body2" className="transaction-id-text">
            Transaction ID: <a href={getExplorerTxUrl(txId)} target="_blank" rel="noreferrer">{txId}</a>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TokenTransfer;
