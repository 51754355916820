// src/components/swap/SwapView.tsx
import React, { useEffect, useState } from 'react';
import { Account, AccountToken } from '../../tronApi/tronTypes';
import { apiClient } from '../../api/client';
import { tronClient } from '../../tronApi/tronClient';
import { Token } from '../../types/token';
import './SwapView.css';
import TokenSwap from './TokenSwap';

import { WalletActionButton } from '@tronweb3/tronwallet-adapter-react-ui';
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { convertFromUnit } from '../../utils/balance';

const SwapView: React.FC = () => {
    const [account, setAccount] = useState<Account | null>(null);
    const [allTokens, setAllTokens] = useState<Token[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { address, connected, wallet } = useWallet();

    const fetchTokensWithBalances = async (userAddress: string) => {
        setLoading(true);
        setError(null);
        try {
            // Get user's account info including token balances
            const accountInfo = await tronClient.getAccount(userAddress);
            setAccount(accountInfo);
            const userTokens: AccountToken[] = accountInfo.tokenList || [];

            // Get all available tokens from API
            const apiTokens = await apiClient.getTokens();

            // Create a map of user token balances
            const userBalances = new Map(
                userTokens.map(token => [
                    token.address.toLowerCase(),
                    token.balance
                ])
            );

            // Map all tokens with balances (0 if user doesn't own them)
            const tokensWithBalances = apiTokens.map(token => ({
                address: token.address,
                symbol: token.symbol,
                name: token.name,
                decimals: token.decimals,
                balance: userBalances.has(token.address.toLowerCase())
                    ? convertFromUnit(
                          userBalances.get(token.address.toLowerCase()) || '0',
                          token.decimals
                      ).toString()
                    : '0',
                logo: token.logo,
                whitelist: token.whitelist
            }));

            setAllTokens(tokensWithBalances);
        } catch (err) {
            console.error('Error fetching tokens:', err);
            setError('Failed to fetch tokens');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (connected && address) {
            fetchTokensWithBalances(address);
        }
    }, [connected, address]);

    if (!connected) {
        return (
            <div className="swap-view">
                <div className="not-connected-container">
                    <div className="not-connected-content">
                        <WalletActionButton></WalletActionButton>
                        <h2>Connect Your Wallet</h2>
                        <p>Please connect your TronLink wallet to start swapping tokens</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="swap-view">
            <div className="swap-view-header">
                <div className="account-info">
                    <h2>Swap Tokens</h2>
                    <p className="wallet-address">Wallet: {address}</p>
                    {account?.balance && (
                        <p className="wallet-balance">
                            Balance: {convertFromUnit(account.balance?.toString(), 6)} TRX
                        </p>
                    )}
                </div>
            </div>

            {loading ? (
                <div className="loading-container">
                    <div className="loader"></div>
                    <p>Loading tokens...</p>
                </div>
            ) : error ? (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="swap-content">
                    <div className="swap-interface">
                        <TokenSwap
                            tokens={allTokens}
                            defaultTokenAddress={allTokens[0]?.address}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SwapView;
