
// api/tronClient.ts
import axios, { AxiosInstance } from 'axios';

// Define interfaces for Tron-specific types
// First, let's define the interfaces
import { Account, AccountResourceRequest, AccountResourceResponse, TronTransaction } from './tronTypes';


// Helper function to convert timestamp to formatted date
const convertTimesecondsToStringTime = (timestamp: number): string => {
  if (timestamp === 0) return 'N/A';
  return new Date(timestamp).toLocaleString();
};

class TronClient {
  private client: AxiosInstance;
  private apiKey: string;

  constructor(baseURL: string, apiKey?: string) {
    this.apiKey = apiKey || process.env.NEXT_PUBLIC_TRON_API_KEY || '';
    this.client = axios.create({
      baseURL,
      headers: {
        'TRON-PRO-API-KEY': this.apiKey,
        'Content-Type': 'application/json'
      }
    });
  }

  async getAccountResource(address: string): Promise<AccountResourceResponse> {
    const request: AccountResourceRequest = {
      address,
      visible: true
    };

    try {
      const response = await this.client.post('/wallet/getaccountresource', request);
      return response.data;
    } catch (error) {
      console.error('Error fetching account resources:', error);
      throw error;
    }
  }

  async getAccount(address: string): Promise<Account> {
    try {
      const { data } = await this.client.get(`/v1/accounts/${address}`);

      let account: Account = {
        isActive: false,
        address: address,
        balance: 0,
        ownerPermission: '',
        activePermission: '',
        createTime: '',
        tokenList: [],
        latestOperation: '',
        bandwidth: -1,
        bandwidthUsed: -1,
        energy: -1,
        energyUsed: -1,
        freeBandwidth: -1,
        freeBandwidthUsed: -1
      };

      if (data.data && data.data.length > 0) {
        const accountData = data.data[0];

        // Active account
        account.isActive = true;

        // Set basic account info
        account.balance = accountData.balance
          ? Number(accountData.balance) / 1_000_000
          : 0;

        account.createTime = accountData.create_time
          ? convertTimesecondsToStringTime(accountData.create_time)
          : 'N/A';

        // Process TRC20 tokens
        if (accountData.trc20 && Array.isArray(accountData.trc20)) {
          try {
            account.tokenList = accountData.trc20.map((tokenObj: Record<string, string>) => {
              const tokenAddress = Object.keys(tokenObj)[0];
              const tokenBalance = tokenObj[tokenAddress];

              return {
                address: tokenAddress,
                name: '',
                balance: tokenBalance,
              };
            });
          } catch (error) {
            console.error('Error processing TRC20 tokens:', error);
            account.tokenList = [];
          }
        }

        // Get updated account with energy values
        return await this.refreshAccountEnergy(account);
      }
      else {
        console.error('No account data found');
        console.error('Account data:', data);
      }

      return account;

    } catch (error) {
      console.error('Error fetching account:', error);
      throw error;
    }
  }

  async refreshAccountEnergy(account: Account): Promise<Account> {
    try {
      const resources = await this.getAccountResource(account.address);

      // Update account object with new values
      account.energy = resources.EnergyLimit ? resources.EnergyLimit : 0;
      account.energyUsed = resources.EnergyUsed ? resources.EnergyUsed : 0;
      account.bandwidth = resources.NetLimit ? resources.NetLimit : 0;
      account.bandwidthUsed = resources.NetUsed ? resources.NetUsed : 0;
      account.freeBandwidth = resources.freeNetLimit ? resources.freeNetLimit : 0;
      account.freeBandwidthUsed = resources.freeNetUsed ? resources.freeNetUsed : 0;

      // Return updated account object
      return account;
    } catch (error) {
      console.error('Error refreshing account energy:', error);
      throw error;
    }
  }

  async getAccountBalance(address: string): Promise<string> {
    const { data } = await this.client.get(`/v1/accounts/${address}/balance`);
    return data.balance;
  }

  // Token related endpoints
  async getTokenBalance(address: string, tokenAddress: string): Promise<string> {
    const { data } = await this.client.get(`/v1/accounts/${address}/tokens/${tokenAddress}`);
    return data.balance;
  }

  async getTrc20TokenBalance(address: string, contractAddress: string): Promise<string> {
    const { data } = await this.client.post('/wallet/triggerconstantcontract', {
      owner_address: address,
      contract_address: contractAddress,
      function_selector: 'balanceOf(address)',
      parameter: address
    });
    return data.constant_result[0];
  }

  // Transaction related endpoints
  async sendTransaction(transaction: {
    from: string;
    to: string;
    amount: string;
    privateKey: string;
  }): Promise<TronTransaction> {
    const { data } = await this.client.post('/wallet/createtransaction', {
      owner_address: transaction.from,
      to_address: transaction.to,
      amount: transaction.amount
    });
    return data;
  }

  async getTransactionStatus(txId: string): Promise<TronTransaction> {
    const { data } = await this.client.get(`/v1/transactions/${txId}`);
    return data;
  }

  // Smart contract related endpoints
  async triggerSmartContract(params: {
    contractAddress: string;
    functionSelector: string;
    parameter: string;
    ownerAddress: string;
  }): Promise<any> {
    const { data } = await this.client.post('/wallet/triggersmartcontract', {
      contract_address: params.contractAddress,
      function_selector: params.functionSelector,
      parameter: params.parameter,
      owner_address: params.ownerAddress,
    });
    return data;
  }
}


const isNileTestnet= process.env.REACT_APP_NETWORK === 'Nile';
const isShastaTestnet = process.env.REACT_APP_NETWORK === 'Shasta';

const fullHost = isNileTestnet 
  ? process.env.REACT_APP_TRON_NILE_FULL_HOST 
  : isShastaTestnet 
    ? process.env.REACT_APP_TRON_SHASTA_FULL_HOST 
    : process.env.REACT_APP_TRON_NODE_FULL_HOST;

const apiKey = isNileTestnet 
  ? process.env.REACT_APP_TRON_NILE_TOKEN 
  : isShastaTestnet 
    ? process.env.REACT_APP_TRON_SHASTA_TOKEN 
    : process.env.REACT_APP_TRON_NODE_TOKEN;

// Create and export a singleton instance
export const tronClient = new TronClient(
  fullHost || 'https://api.trongrid.io',
  apiKey
);



