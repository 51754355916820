import React from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PoolIcon from '@mui/icons-material/Pool';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Link } from 'react-router-dom';


import '../../../styles/brand/landing/Product.css';


interface Product {
    title: string;
    description: string;
    icon: React.ReactNode;
    path: string;
}


const products: Product[] = [
    {
        title: 'Swap',
        description: 'Exchange tokens instantly with the best rates and lowest fees on TRON',
        icon: <SwapHorizIcon className="product-icon" />,
        path: '/swap'
    },
    {
        title: 'Pool',
        description: 'Provide liquidity and earn passive income from trading fees',
        icon: <PoolIcon className="product-icon" />,
        path: '/pool'
    },
    {
        title: 'Analytics',
        description: 'Track your portfolio and analyze market trends in real-time',
        icon: <QueryStatsIcon className="product-icon" />,
        path: '/analytics'
    }
];

const Product: React.FC = () => {
    return (
        <section className="product-section">
            <Box className="products-section">
                <Container maxWidth="lg">
                    <Typography variant="h3" className="section-title">
                        Our Products
                    </Typography>
                    <Grid container spacing={4}>
                        {products.map((product, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Paper
                                    className="product-card"
                                    component={Link}
                                    to={product.path}
                                >
                                    <Box className="product-icon-wrapper">
                                        {product.icon}
                                    </Box>
                                    <Typography variant="h5" className="product-title">
                                        {product.title}
                                    </Typography>
                                    <Typography variant="body1" className="product-description">
                                        {product.description}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </section>
    );
};

export default Product;
