// src/components/swap/TokenSwap.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { Token } from '../../types/token';
import { formatBalance, validateAddress, calculateExchangeRate } from '../../utils/tokenUtils';
import { SwapIcon, WarningIcon, LoadingSpinner } from '../icons';
import './TokenSwap.css';

interface TokenSwapProps {
    tokens: Token[];
    defaultTokenAddress?: string;
    onCustomTokenAdd?: (address: string) => Promise<Token | null>;
    onSwap?: (fromToken: Token, toToken: Token, amount: string) => Promise<boolean>;
    isLoading?: boolean;
}

interface SwapState {
    loading: boolean;
    error: string | null;
    success: boolean;
}

const TokenSwap: React.FC<TokenSwapProps> = ({
    tokens,
    defaultTokenAddress,
    onCustomTokenAdd,
    onSwap,
    isLoading = false
}) => {
    // State management
    const [fromToken, setFromToken] = useState<Token | null>(null);
    const [toToken, setToToken] = useState<Token | null>(null);
    const [fromAmount, setFromAmount] = useState<string>('');
    const [toAmount, setToAmount] = useState<string>('');
    const [slippage, setSlippage] = useState<string>('0.5');
    const [customAddress, setCustomAddress] = useState<string>('');
    const [customTokens, setCustomTokens] = useState<Token[]>([]);
    const [swapState, setSwapState] = useState<SwapState>({
        loading: false,
        error: null,
        success: false
    });
    const [searchQuery, setSearchQuery] = useState<string>('');

    // Memoized values
    const allTokens = useMemo(() => [...tokens, ...customTokens], [tokens, customTokens]);
    
    const filteredTokens = useMemo(() => {
        const query = searchQuery.toLowerCase();
        return allTokens.filter(token => 
            token.symbol.toLowerCase().includes(query) ||
            token.address.toLowerCase().includes(query) ||
            token.name?.toLowerCase().includes(query)
        );
    }, [allTokens, searchQuery]);

    const isTokenWhitelisted = (token: Token | null) => {
        if (!token) return true;
        return token.whitelist?.toLowerCase().startsWith('y') ?? false;
    };

    const whitelistedTokens = useMemo(() => 
        filteredTokens.filter(isTokenWhitelisted), 
        [filteredTokens]
    );

    // Effects
    useEffect(() => {
        if (whitelistedTokens.length > 0) {
            const defaultToken = whitelistedTokens.find(t => t.address === defaultTokenAddress) 
                || whitelistedTokens[0];
            setFromToken(defaultToken);
            setToToken(whitelistedTokens.length > 1 ? whitelistedTokens[1] : whitelistedTokens[0]);
        }
    }, [whitelistedTokens, defaultTokenAddress]);

    // Calculate exchange rate whenever from/to tokens or amounts change
    useEffect(() => {
        if (fromToken && toToken && fromAmount) {
            const rate = calculateExchangeRate(fromToken, toToken, fromAmount);
            setToAmount(rate);
        }
    }, [fromToken, toToken, fromAmount]);

    // Handlers
    const handleAddCustomToken = async () => {
        if (!customAddress || !onCustomTokenAdd) return;

        try {
            setSwapState({ loading: true, error: null, success: false });

            if (!validateAddress(customAddress)) {
                throw new Error('Invalid token address');
            }

            const customToken = await onCustomTokenAdd(customAddress);
            if (customToken) {
                if (!allTokens.some(t => t.address.toLowerCase() === customToken.address.toLowerCase())) {
                    setCustomTokens(prev => [...prev, {
                        ...customToken,
                        whitelist: 'n'
                    }]);
                    setSwapState({ loading: false, error: null, success: true });
                } else {
                    throw new Error('Token already exists');
                }
                setCustomAddress('');
            }
        } catch (error) {
            setSwapState({ 
                loading: false, 
                error: error instanceof Error ? error.message : 'Failed to add token', 
                success: false 
            });
        }
    };

    const handleSwapTokens = () => {
        setFromToken(toToken);
        setToToken(fromToken);
        setFromAmount(toAmount);
        setToAmount(fromAmount);
    };

    const handleFromAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setFromAmount(value);
        }
    };

    const handleSwap = async () => {
        if (!fromToken || !toToken || !fromAmount || !onSwap) return;

        try {
            setSwapState({ loading: true, error: null, success: false });
            const success = await onSwap(fromToken, toToken, fromAmount);
            
            if (success) {
                setSwapState({ loading: false, error: null, success: true });
                // Reset form
                setFromAmount('');
                setToAmount('');
            } else {
                throw new Error('Swap failed');
            }
        } catch (error) {
            setSwapState({ 
                loading: false, 
                error: error instanceof Error ? error.message : 'Swap failed', 
                success: false 
            });
        }
    };

    const showWarning = !isTokenWhitelisted(fromToken) || !isTokenWhitelisted(toToken);
    const insufficientBalance = fromToken && 
        fromAmount && 
        parseFloat(fromAmount) > parseFloat(fromToken.balance || '0');

    return (
        <div className="swap-container">
            <div className="swap-card">
                <div className="swap-header">
                    <h2>Swap Tokens</h2>
                    
                    {/* Search and Custom Token Input */}
                    <div className="token-search">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search tokens..."
                            className="search-input"
                        />
                    </div>

                    <div className="custom-token-input">
                        <input
                            type="text"
                            value={customAddress}
                            onChange={(e) => setCustomAddress(e.target.value)}
                            placeholder="Enter token address"
                            className={swapState.error ? 'error' : ''}
                        />
                        <button 
                            onClick={handleAddCustomToken}
                            disabled={!customAddress || swapState.loading}
                        >
                            {swapState.loading ? <LoadingSpinner /> : 'Add Token'}
                        </button>
                    </div>

                    {swapState.error && (
                        <div className="error-message">
                            {swapState.error}
                        </div>
                    )}

                    {showWarning && (
                        <div className="warning-message">
                            <WarningIcon />
                            <span>One or both tokens are not whitelisted. Trade with caution!</span>
                        </div>
                    )}

                    {/* Slippage Settings */}
                    <div className="slippage-settings">
                        <label>Slippage Tolerance:</label>
                        <select 
                            value={slippage}
                            onChange={(e) => setSlippage(e.target.value)}
                        >
                            <option value="0.1">0.1%</option>
                            <option value="0.5">0.5%</option>
                            <option value="1.0">1.0%</option>
                            <option value="2.0">2.0%</option>
                        </select>
                    </div>
                </div>

                <div className="token-input-container">
                    {/* From Token Section */}
                    <div className={`token-input ${insufficientBalance ? 'error' : ''}`}>
                        <select 
                            value={fromToken?.address}
                            onChange={(e) => setFromToken(allTokens.find(t => t.address === e.target.value) || null)}
                        >
                            <optgroup label="Whitelisted Tokens">
                                {whitelistedTokens.map(token => (
                                    <option key={token.address} value={token.address}>
                                        {token.symbol} - {token.name}
                                    </option>
                                ))}
                            </optgroup>
                            <optgroup label="Other Tokens">
                                {filteredTokens
                                    .filter(token => !isTokenWhitelisted(token))
                                    .map(token => (
                                        <option key={token.address} value={token.address}>
                                            {token.symbol} - {token.name} (Non-whitelisted)
                                        </option>
                                    ))
                                }
                            </optgroup>
                        </select>
                        <div className="token-input-field">
                            <input
                                type="text"
                                value={fromAmount}
                                onChange={handleFromAmountChange}
                                placeholder="0.0"
                                className={insufficientBalance ? 'error' : ''}
                            />
                            {fromToken && (
                                <button 
                                    className="max-button"
                                    onClick={() => setFromAmount(fromToken.balance || '0')}
                                >
                                    MAX
                                </button>
                            )}
                        </div>
                        {fromToken && (
                            <div className="token-balance">
                                Balance: {formatBalance(fromToken.balance)} {fromToken.symbol}
                            </div>
                        )}
                        {insufficientBalance && (
                            <div className="error-message">Insufficient balance</div>
                        )}
                    </div>

                    <button 
                        className="swap-direction-button" 
                        onClick={handleSwapTokens}
                        title="Swap tokens"
                    >
                        <SwapIcon />
                    </button>

                    {/* To Token Section */}
                    <div className="token-input">
                        <select
                            value={toToken?.address}
                            onChange={(e) => setToToken(allTokens.find(t => t.address === e.target.value) || null)}
                        >
                            <optgroup label="Whitelisted Tokens">
                                {whitelistedTokens.map(token => (
                                    <option key={token.address} value={token.address}>
                                        {token.symbol} - {token.name}
                                    </option>
                                ))}
                            </optgroup>
                            <optgroup label="Other Tokens">
                                {filteredTokens
                                    .filter(token => !isTokenWhitelisted(token))
                                    .map(token => (
                                        <option key={token.address} value={token.address}>
                                            {token.symbol} - {token.name} (Non-whitelisted)
                                        </option>
                                    ))
                                }
                            </optgroup>
                        </select>
                        <input
                            type="text"
                            value={toAmount}
                            readOnly
                            placeholder="0.0"
                        />
                        {toToken && (
                            <div className="token-balance">
                                Balance: {formatBalance(toToken.balance)} {toToken.symbol}
                            </div>
                        )}
                    </div>
                </div>

                {/* Exchange Rate Info */}
                {fromToken && toToken && fromAmount && (
                    <div className="swap-info">
                        <div className="rate-info">
                            <span>Exchange Rate:</span>
                            <span>
                                1 {fromToken.symbol} = {calculateExchangeRate(fromToken, toToken, '1')} {toToken.symbol}
                            </span>
                        </div>
                        <div className="slippage-info">
                            <span>Max Slippage:</span>
                            <span>{slippage}%</span>
                        </div>
                        <div className="minimum-received">
                            <span>Minimum Received:</span>
                            <span>
                                {calculateExchangeRate(fromToken, toToken, fromAmount, parseFloat(slippage))} {toToken.symbol}
                            </span>
                        </div>
                    </div>
                )}

                <button 
                    className="swap-button"
                    onClick={handleSwap}
                    disabled={!fromAmount || !toAmount || insufficientBalance || isLoading || swapState.loading}
                >
                    {isLoading || swapState.loading ? (
                        <LoadingSpinner />
                    ) : insufficientBalance ? (
                        'Insufficient Balance'
                    ) : (
                        'Swap'
                    )}
                </button>
            </div>
        </div>
    );
};

export default TokenSwap;
