// src/pages/Terms/Terms.tsx
import React from 'react';
import { Container, Typography, Box, Paper, Divider } from '@mui/material';
import '../../styles/brand/TOS.css';

interface TermSection {
  title: string;
  content: string[];
}

const termSections: TermSection[] = [
  {
    title: "1. Acceptance of Terms",
    content: [
      "By accessing and using this platform, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.",
      "If you do not agree with any part of these terms, you must not use our platform."
    ]
  },
  {
    title: "2. Platform Services",
    content: [
      "Our platform facilitates transactions on the TRON network, allowing users to manage and transfer digital assets.",
      "We do not own or control the TRON network, and the availability of our services depends on the network's functionality.",
      "Users are responsible for maintaining the security of their private keys and wallets."
    ]
  },
  {
    title: "3. User Responsibilities",
    content: [
      "You must provide accurate and complete information when using our platform.",
      "You are responsible for all activities that occur under your account.",
      "You must not use our platform for any illegal purposes or in violation of any applicable laws.",
      "You must not attempt to interfere with or disrupt the platform's functionality."
    ]
  },
  {
    title: "4. Risks and Disclaimers",
    content: [
      "Cryptocurrency transactions are irreversible. We cannot recover or reverse incorrect transactions.",
      "Digital asset values can be highly volatile. You accept all risks associated with trading and holding digital assets.",
      "We are not responsible for any losses incurred due to network issues, market volatility, or user error."
    ]
  },
  {
    title: "5. Privacy and Security",
    content: [
      "We prioritize the security of user information and transactions.",
      "We do not store private keys or have access to users' wallets.",
      "Users are responsible for maintaining the security of their own wallets and credentials."
    ]
  },
  {
    title: "6. Service Modifications",
    content: [
      "We reserve the right to modify, suspend, or discontinue any part of our service at any time.",
      "We may update these terms periodically. Continued use of the platform constitutes acceptance of any changes."
    ]
  },
  {
    title: "7. Limitation of Liability",
    content: [
      "Our platform is provided 'as is' without any warranties, expressed or implied.",
      "We shall not be liable for any indirect, incidental, special, or consequential damages.",
      "Our total liability for any claims shall not exceed the amount you paid to use our services."
    ]
  },
  {
    title: "8. Governing Law",
    content: [
      "These terms shall be governed by and construed in accordance with applicable laws.",
      "Any disputes shall be resolved through arbitration in accordance with the rules of the relevant jurisdiction."
    ]
  }
];

const Terms: React.FC = () => {
  return (
    <Container className="terms-container">
      <Paper className="terms-paper" elevation={3}>
        <Box className="terms-content">
          <Typography variant="h4" component="h1" className="terms-title">
            Terms of Service
          </Typography>

          <Typography variant="body1" className="terms-last-updated">
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" className="terms-introduction">
            Welcome to our Web3 platform. These Terms of Service govern your access to and use of our platform
            and services. Please read these terms carefully before using our platform.
          </Typography>

          <Box className="terms-sections">
            {termSections.map((section, index) => (
              <Box key={index} className="terms-section">
                <Typography variant="h6" className="section-title">
                  {section.title}
                </Typography>
                {section.content.map((paragraph, pIndex) => (
                  <Typography key={pIndex} variant="body1" className="section-content">
                    {paragraph}
                  </Typography>
                ))}
                {index < termSections.length - 1 && <Divider className="section-divider" />}
              </Box>
            ))}
          </Box>

          <Box className="terms-footer">
            <Typography variant="body2" color="textSecondary">
              If you have any questions about these Terms of Service, please contact us.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Terms;
