// src/pages/brand/Privacy.tsx
import React from 'react';
import { Container, Typography, Box, Paper, Divider } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ShareIcon from '@mui/icons-material/Share';
import StorageIcon from '@mui/icons-material/Storage';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import '../../styles/brand/Privacy.css';

interface PrivacySection {
  title: string;
  icon: React.ReactNode;
  content: string[];
}

const privacySections: PrivacySection[] = [
  {
    title: "Information We Collect",
    icon: <DataUsageIcon className="section-icon" />,
    content: [
      "We collect information that you provide directly to us when you:",
      "• Create and use an account on our platform",
      "• Connect your wallet to our services",
      "• Interact with smart contracts",
      "• Communicate with our support team",
      "• Subscribe to our newsletters or updates",
      "• Participate in our surveys or promotions"
    ]
  },
  {
    title: "How We Use Your Information",
    icon: <StorageIcon className="section-icon" />,
    content: [
      "We use the collected information to:",
      "• Provide and maintain our services",
      "• Process your transactions",
      "• Send you important updates and notifications",
      "• Improve our platform and user experience",
      "• Detect and prevent fraudulent activities",
      "• Comply with legal obligations"
    ]
  },
  {
    title: "Information Sharing",
    icon: <ShareIcon className="section-icon" />,
    content: [
      "We do not sell or rent your personal information to third parties. We may share your information only in the following circumstances:",
      "• With your explicit consent",
      "• To comply with legal requirements",
      "• To protect our rights and safety",
      "• With service providers who assist in our operations"
    ]
  },
  {
    title: "Data Security",
    icon: <SecurityIcon className="section-icon" />,
    content: [
      "We implement appropriate security measures to protect your information:",
      "• Encryption of sensitive data",
      "• Regular security assessments",
      "• Secure server infrastructure",
      "• Limited access to personal information",
      "• Continuous monitoring for potential breaches"
    ]
  },
  {
    title: "Data Retention and Deletion",
    icon: <DeleteIcon className="section-icon" />,
    content: [
      "We retain your information only for as long as necessary to:",
      "• Provide our services to you",
      "• Comply with legal obligations",
      "• Resolve disputes",
      "You can request deletion of your personal data by contacting our support team."
    ]
  },
  {
    title: "Updates to Privacy Policy",
    icon: <UpdateIcon className="section-icon" />,
    content: [
      "We may update this Privacy Policy from time to time. We will notify you of any changes by:",
      "• Posting the new Privacy Policy on our website",
      "• Sending you an email notification",
      "• Displaying a notice on our platform"
    ]
  },
  {
    title: "Contact Us",
    icon: <ContactSupportIcon className="section-icon" />,
    content: [
      "If you have any questions about our Privacy Policy, please contact us at:",
      "Email: privacy@onchainvision.com",
      "We aim to respond to all inquiries within 48 hours."
    ]
  }
];

const Privacy: React.FC = () => {
  return (
    <Container className="privacy-container">
      <Paper className="privacy-paper" elevation={3}>
        <Box className="privacy-content">
          <Typography variant="h4" component="h1" className="privacy-title">
            Privacy Policy
          </Typography>

          <Typography variant="body1" className="privacy-last-updated">
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" className="privacy-introduction">
            At OnChainVision, we take your privacy seriously. This Privacy Policy explains how we collect,
            use, and protect your personal information when you use our platform.
          </Typography>

          <Box className="privacy-sections">
            {privacySections.map((section, index) => (
              <Box key={index} className="privacy-section">
                <Box className="section-header">
                  {section.icon}
                  <Typography variant="h6" className="section-title">
                    {section.title}
                  </Typography>
                </Box>
                <Box className="section-content">
                  {section.content.map((paragraph, pIndex) => (
                    <Typography key={pIndex} variant="body1" className="content-paragraph">
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
                {index < privacySections.length - 1 && <Divider className="section-divider" />}
              </Box>
            ))}
          </Box>

          <Box className="privacy-footer">
            <Typography variant="body2" color="textSecondary">
              This Privacy Policy is effective as of {new Date().toLocaleDateString()} and will remain in effect except
              with respect to any changes in its provisions in the future.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Privacy;
