import { tronWeb } from '../tronweb';
import { checkTransactionWithTimeout } from './transactions';

export const delegateEnergy = async (address: string, amount: number) => {
    try {
        console.log('Delegate Energy Address:', address);
        // Build the delegate resource transaction
        const transaction = await tronWeb.transactionBuilder.delegateResource(
            amount,              // amount to delegate
            address,            // receiver address
            'ENERGY',          // resource type
            process.env.REACT_APP_OWNER_ADDRESS,  // owner address
            false,             // for optimization of your energy delegation
            // {
            //     permissionId: 2  // specify permission ID for multi-sig
            // }
        );

        if (!transaction) {
            throw new Error('Failed to create delegation transaction');
        }

        // console.log('Delegation Transaction Created:', transaction);

        // Sign the transaction
        const signedTx = await tronWeb.trx.sign(transaction, process.env.REACT_APP_PRIVATE_KEY);
        // console.log('Signed Transaction:', signedTx);

        if (!signedTx) {
            throw new Error('Failed to sign transaction');
        }

        // Broadcast the transaction
        const result = await tronWeb.trx.sendRawTransaction(signedTx);
        console.log('Broadcast Result:', result);

        if (!result.result) {
            throw new Error('Transaction broadcast failed');
        }

        // Wait for the transaction to be confirmed
        await checkTransactionWithTimeout(result.txid || result.transaction.txID);

        return {
            success: true,
            transaction: result,
            txID: result.txid || result.transaction.txID
        };

    } catch (error) {
        console.error('Delegation Error:', error);
        throw new Error(`Failed to delegate energy: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};

export const delegateBandwidth = async (address: string, amount: number) => {
    try {
        console.log('Delegate Bandiwdth Address:', address);
        // Build the delegate resource transaction
        const transaction = await tronWeb.transactionBuilder.delegateResource(
            amount,              // amount to delegate
            address,            // receiver address
            'BANDWIDTH',          // resource type
            process.env.REACT_APP_OWNER_ADDRESS,  // owner address
            false,             // for optimization of your energy delegation
            // {
            //     permissionId: 2  // specify permission ID for multi-sig
            // }
        );

        if (!transaction) {
            throw new Error('Failed to create delegation transaction');
        }
        // console.log('Delegation Transaction Created:', transaction);

        // Sign the transaction
        const signedTx = await tronWeb.trx.sign(transaction, process.env.REACT_APP_PRIVATE_KEY);
        // console.log('Signed Transaction:', signedTx);

        if (!signedTx) {
            throw new Error('Failed to sign transaction');
        }

        // Broadcast the transaction
        const result = await tronWeb.trx.sendRawTransaction(signedTx);
        console.log('Broadcast Result:', result);

        if (!result.result) {
            throw new Error('Transaction broadcast failed');
        }

        // Wait for the transaction to be confirmed
        await checkTransactionWithTimeout(result.txid || result.transaction.txID);

        return {
            success: true,
            transaction: result,
            txID: result.txid || result.transaction.txID
        };

    } catch (error) {
        console.error('Delegation Error:', error);
        throw new Error(`Failed to delegate energy: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};


export const undelegateEnergy = async (address: string, amount: number) => {
    try {
        console.log('Undelegate Energy Address:', address);
        const transaction = await tronWeb.transactionBuilder.undelegateResource(
            amount,
            address,
            'ENERGY',
            process.env.REACT_APP_OWNER_ADDRESS,
            // {
            //     permissionId: 3
            // }
        );

        if (!transaction) {
            throw new Error('Failed to create undelegation transaction');
        }

        // console.log('Undelegation Transaction Created:', transaction);

        const signedTx = await tronWeb.trx.sign(transaction, process.env.REACT_APP_PRIVATE_KEY);
        // console.log('Signed Transaction:', signedTx);

        if (!signedTx) {
            throw new Error('Failed to sign transaction');
        }

        const result = await tronWeb.trx.sendRawTransaction(signedTx);
        console.log('Broadcast Result:', result);

        if (!result.result) {
            throw new Error('Transaction broadcast failed');
        }

        // Wait for the transaction to be confirmed
        await checkTransactionWithTimeout(result.txid || result.transaction.txID);

        return {
            success: true,
            transaction: result,
            txID: result.txid || result.transaction.txID
        };

    } catch (error) {
        console.error('Undelegation Error:', error);
        throw new Error(`Failed to undelegate energy: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};


export const undelegateBandwidth = async (address: string, amount: number) => {
    try {
        console.log('Undelegate Energy Address:', address);
        const transaction = await tronWeb.transactionBuilder.undelegateResource(
            amount,
            address,
            'BANDWIDTH',
            process.env.REACT_APP_OWNER_ADDRESS,
            // {
            //     permissionId: 3
            // }
        );

        if (!transaction) {
            throw new Error('Failed to create undelegation transaction');
        }

        // console.log('Undelegation Transaction Created:', transaction);

        const signedTx = await tronWeb.trx.sign(transaction, process.env.REACT_APP_PRIVATE_KEY);
        // console.log('Signed Transaction:', signedTx);

        if (!signedTx) {
            throw new Error('Failed to sign transaction');
        }

        const result = await tronWeb.trx.sendRawTransaction(signedTx);
        console.log('Broadcast Result:', result);

        if (!result.result) {
            throw new Error('Transaction broadcast failed');
        }

        // Wait for the transaction to be confirmed
        await checkTransactionWithTimeout(result.txid || result.transaction.txID);

        return {
            success: true,
            transaction: result,
            txID: result.txid || result.transaction.txID
        };

    } catch (error) {
        console.error('Undelegation Error:', error);
        throw new Error(`Failed to undelegate energy: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};


// Function to check delegated energy
export const getDelegatedEnergy = async (address: string) => {
    try {
        if (!tronWeb.isAddress(address)) {
            throw new Error('Invalid address');
        }

        const resourceInfo = await tronWeb.trx.getDelegatedResourceAccountIndex(address);
        console.log('Delegated Resource Info:', resourceInfo);
        
        return resourceInfo;

    } catch (error) {
        console.error('Error getting delegated energy:', error);
        throw new Error(`Failed to get delegated energy: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};

export interface DelegationResult {
    success: boolean;
    transaction?: any;
    txID?: string;
    error?: string;
}

export interface DelegatedResourceInfo {
    delegatedResource?: any[];
    delegatedResourceTotal?: number;
    fromAddress?: string;
    toAddress?: string;
}

