// src/utils/tokenUtils.ts
import { Token } from '../types/token';

export const formatBalance = (balance?: string): string => {
    if (!balance) return '0';
    const num = parseFloat(balance);
    if (num === 0) return '0';
    if (num < 0.0001) return '<0.0001';
    return num.toLocaleString(undefined, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 0
    });
};

export const validateAddress = (address: string): boolean => {
    // Implement your address validation logic here
    // This is a basic example - adjust according to your blockchain
    return /^(0x)?[0-9a-fA-F]{40}$/.test(address);
};

export const calculateExchangeRate = (
    fromToken: Token,
    toToken: Token,
    amount: string,
    slippage: number = 0
): string => {
    // Implement your exchange rate calculation logic here
    // This is a placeholder implementation
    const rate = 1; // Replace with actual rate calculation
    const value = parseFloat(amount) * rate;
    if (slippage > 0) {
        return (value * (1 - slippage / 100)).toFixed(4);
    }
    return value.toFixed(4);
};
