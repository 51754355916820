
import React, { useState } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Grid, 
  Paper, 
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleIcon from '@mui/icons-material/People';
import '../../../styles/brand/landing/Achievement.css';

interface Achievement {
  title: string;
  value: string;
  description: string;
  icon: React.ReactNode;
}

const achievements: Achievement[] = [
    {
      title: 'Hackathon Wins',
      value: '5+',
      description: 'First place in multiple TRON hackathons',
      icon: <EmojiEventsIcon />
    },
    {
      title: 'Total Volume',
      value: '$10M+',
      description: 'Total trading volume processed',
      icon: <QueryStatsIcon />
    },
    {
      title: 'Active Users',
      value: '10K+',
      description: 'Monthly active users on our platform',
      icon: <PeopleIcon />
    }
  ];
  

const Achievements: React.FC = () => {
    return (
        <section className="achievement-section">
            {/* Achievement section content */}
            <Box className="achievements-section">
                <Container maxWidth="lg">
                    <Typography variant="h3" className="section-title">
                        Our Achievements
                    </Typography>
                    <Grid container spacing={4}>
                        {achievements.map((achievement, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Paper className="achievement-card">
                                    <Box className="achievement-icon">
                                        {achievement.icon}
                                    </Box>
                                    <Typography variant="h3" className="achievement-value">
                                        {achievement.value}
                                    </Typography>
                                    <Typography variant="h6" className="achievement-title">
                                        {achievement.title}
                                    </Typography>
                                    <Typography variant="body1" className="achievement-description">
                                        {achievement.description}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </section>
    );
};

export default Achievements;
