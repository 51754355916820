// src/pages/Contact/Contact.tsx
import React from 'react';
import { Container, Typography, Box, Paper, Grid, IconButton, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import XIcon from '@mui/icons-material/X';
import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';
import { FaDiscord as DiscordIcon } from "react-icons/fa";
import '../../styles/brand/Contact.css';

interface ContactInfo {
  title: string;
  content: string;
  icon: React.ReactNode;
  link: string;
  color?: string;
}

const ContactDetails: ContactInfo[] = [
  {
    title: 'Email',
    content: 'onchainvision@gmail.com',
    icon: <EmailIcon className="contact-icon" />,
    link: 'mailto:onchainvision@gmail.com',
    color: '#EA4335'
  },
  {
    title: 'X (Twitter)',
    content: '@onchainvision',
    icon: <XIcon className="contact-icon" />,
    link: 'https://x.com/onchainvision',
    color: '#000000'
  },
  {
    title: 'Discord',
    content: 'onchainvision',
    icon: <DiscordIcon className="contact-icon" />,
    link: 'https://discord.gg/onchainvision',
    color: '#5865F2'
  },
  {
    title: 'Telegram',
    content: '@onchainvision',
    icon: <TelegramIcon className="contact-icon" />,
    link: 'https://t.me/onchainvision',
    color: '#229ED9'
  },
  {
    title: 'Reddit',
    content: 'r/onchainvision',
    icon: <RedditIcon className="contact-icon" />,
    link: 'https://reddit.com/r/onchainvision',
    color: '#FF4500'
  },
  {
    title: 'Location',
    content: 'WEB3',
    icon: <LocationOnIcon className="contact-icon" />,
    link: 'https://web3.onchainvision.com',
    color: '#34A853'
  },
];

const ContactInfoItem: React.FC<ContactInfo> = ({ title, content, icon, link, color }) => (
  <a 
    href={link} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="contact-link"
  >
    <Box 
      className="contact-details"
      sx={{ 
        '&:hover': { 
          borderColor: color,
          '& .contact-icon': {
            color: color
          }
        }
      }}
    >
      <Box className="contact-details-header">
        {icon}
        <Typography variant="h6" className="contact-title">
          {title}
        </Typography>
      </Box>
      <Typography variant="body1" className="contact-content">
        {content}
      </Typography>
    </Box>
  </a>
);

const SocialButtons: React.FC = () => (
  <Box className="social-buttons">
    {ContactDetails.map((contact, index) => (
      <Tooltip title={`Connect on ${contact.title}`} key={index}>
        <IconButton
          href={contact.link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            color: 'text.primary',
            '&:hover': { 
              color: contact.color,
              transform: 'translateY(-3px)'
            }
          }}
        >
          {contact.icon}
        </IconButton>
      </Tooltip>
    ))}
  </Box>
);

const Contact: React.FC = () => {
  return (
    <Container className="contact-container">
      <Paper className="contact-paper" elevation={3}>
        <Box className="contact-content">
          <Typography variant="h4" component="h1" className="contact-title">
            Get in Touch
          </Typography>

          <Typography variant="body1" className="contact-description">
            Have questions? We'd love to hear from you. Connect with us through any of these platforms.
          </Typography>

          <SocialButtons />

          <Grid container spacing={3} className="contact-grid">
            {ContactDetails.map((detail, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ContactInfoItem {...detail} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default Contact;
