
import React, { useState } from 'react';

//icons import
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import QrCodeIcon from '@mui/icons-material/QrCode';

import { 
    Container, 
    Box, 
    Typography, 
    Grid, 
    Button,
    Chip 
  } from '@mui/material';


import mobile_app_interface from '../../../assets/mobile-app-interface.png';

import '../../../styles/brand/landing/Hero.css';

const Hero: React.FC = () => {
  const [isPhoneHovered, setIsPhoneHovered] = useState(false);

  return (
    <section className="hero-section">
      {/* Hero section content */}
      <Box className="hero-section">
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box className="hero-content">
                <Chip 
                  label="Award Winning DApp" 
                  icon={<EmojiEventsIcon />} 
                  className="hero-chip"
                />
                <Typography variant="h1" className="hero-title">
                  Trade on TRON
                  <br />
                  <span className="gradient-text">Anywhere, Anytime</span>
                </Typography>
                <Typography variant="h5" className="hero-subtitle">
                  Experience the power of DeFi in your pocket with our mobile-first decentralized exchange
                </Typography>
                
                <Box className="hero-cta">
                  <Button 
                    variant="contained" 
                    size="large"
                    className="download-button"
                    startIcon={<PhoneIphoneIcon />}
                  >
                    Download App
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    className="qr-button"
                    startIcon={<QrCodeIcon />}
                  >
                    Scan QR Code
                  </Button>
                </Box>

                <Box className="hero-stats">
                  <Box className="stat-item">
                    <Typography variant="h4" className="stat-value">5+</Typography>
                    <Typography variant="body1" className="stat-label">Hackathon Wins</Typography>
                  </Box>
                  <Box className="stat-divider" />
                  <Box className="stat-item">
                    <Typography variant="h4" className="stat-value">10K+</Typography>
                    <Typography variant="body1" className="stat-label">Active Users</Typography>
                  </Box>
                  <Box className="stat-divider" />
                  <Box className="stat-item">
                    <Typography variant="h4" className="stat-value">$10M+</Typography>
                    <Typography variant="body1" className="stat-label">Total Volume</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className="hero-phone-container">
                <Box 
                  className={`hero-phone ${isPhoneHovered ? 'hovered' : ''}`}
                  onMouseEnter={() => setIsPhoneHovered(true)}
                  onMouseLeave={() => setIsPhoneHovered(false)}
                >
                  <img 
                    src={mobile_app_interface}
                    alt="Mobile App Interface" 
                    className="phone-screen"
                  />
                  <Box className="phone-glow" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

export default Hero;
