
const NETWORK_MAINNET = 'Mainnet';
const NETWORK_SHASTA = 'Shasta';
const NETWORK_NILE = 'Nile';

export const getExplorerTokenUrl = (address: string) => {
    let baseUrl = '';

    switch (process.env.REACT_APP_NETWORK) {
        case NETWORK_MAINNET:
            baseUrl = 'https://tronscan.org/#/token20/';
            break;
        case NETWORK_SHASTA:
            baseUrl = 'https://shasta.tronscan.org/#/token20/';
            break;
        case NETWORK_NILE:
            baseUrl = 'https://nile.tronscan.org/#/token20/';
            break;
        default:
            baseUrl = 'https://tronscan.org/#/token20/';
    }
    return baseUrl + address;
};

export const getExplorerAddressUrl = (address: string) => {
    let baseUrl = '';

    switch (process.env.REACT_APP_NETWORK) {
        case NETWORK_MAINNET:
            baseUrl = 'https://tronscan.org/#/address/';
            break;
        case NETWORK_SHASTA:
            baseUrl = 'https://shasta.tronscan.org/#/address/';
            break;
        case NETWORK_NILE:
            baseUrl = 'https://nile.tronscan.org/#/address/';
            break;
        default:
            baseUrl = 'https://tronscan.org/#/address/';
    }
    return baseUrl + address;
}


export const getExplorerTxUrl = (txId: string) => {
    let baseUrl = '';

    switch (process.env.REACT_APP_NETWORK) {
        case NETWORK_MAINNET:
            baseUrl = 'https://tronscan.org/#/transaction/';
            break;
        case NETWORK_SHASTA:
            baseUrl = 'https://shasta.tronscan.org/#/transaction/';
            break;
        case NETWORK_NILE:
            baseUrl = 'https://nile.tronscan.org/#/transaction/';
            break;
        default:
            baseUrl = 'https://tronscan.org/#/transaction/';
    }
    return baseUrl + txId;
}
