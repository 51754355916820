// src/components/TokenView/TokenView.tsx
import React, { useEffect, useState } from 'react';
// import TokenList from './TokenList';
import { Account, AccountToken } from '../../tronApi/tronTypes';
import { apiClient } from '../../api/client';
import { tronClient } from '../../tronApi/tronClient';
import { Token } from '../../types/token';
import '../../styles/TokenView.css';
import TokenTransfer from './TokenTransfer';

import { WalletActionButton } from '@tronweb3/tronwallet-adapter-react-ui';

import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { convertFromUnit } from '../../utils/balance';

const TokenView: React.FC<{}> = () => {
    const [account, setAccount] = useState<Account | null>(null);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { address, connected, wallet } = useWallet();

    const fetchUserTokens = async (address: string) => {
        setLoading(true);
        setError(null);
        try {
            // Get TRC20 tokens using TronWeb 
            const accountLocal = await tronClient.getAccount(address);
            console.log('Account:', accountLocal);
            setAccount(accountLocal);
            const trc20Tokens: AccountToken[] = accountLocal.tokenList || [];

            // We now should match the tokens with the API tokens based on address
            const tokensAPI = await apiClient.getTokens();

            // Match tokens based on address
            const matchedTokens = trc20Tokens.reduce((acc: Token[], userToken) => {
                const serverToken = tokensAPI.find(
                  (sToken) => sToken.address.toLowerCase() === userToken.address.toLowerCase()
                );
              
                if (!serverToken) {
                  // Skip this token and continue to the next one
                  return acc;
                }
              
                // If serverToken is found, create the matched token
                const matchedToken: Token = {
                  address: userToken.address,
                  symbol: serverToken.symbol,
                  name: serverToken.name,
                  decimals: serverToken.decimals,
                  balance: convertFromUnit(userToken.balance, serverToken.decimals).toString(),
                  logo: serverToken.logo,
                //   verified: serverToken.verified,
                  // Add any other properties you need
                };
              
                return [...acc, matchedToken];
              }, []);

            setTokens(matchedTokens);

        } catch (err) {
            console.error('Error fetching tokens:', err);
            setError('Failed to fetch tokens');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (connected && address) {
            fetchUserTokens(address);
        }
    }, [connected, address]);

    if (!connected) {
        return (
            <div className="token-view">
                <div className="not-connected-container">
                    <div className="not-connected-content">
                        <WalletActionButton></WalletActionButton>
                        <h2>Connect Your Wallet</h2>
                        <p>Please connect your TronLink wallet to view your tokens</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="token-view">
            <div className="token-header">
                <div className="account-info">
                    <h2>Account Details</h2>
                    <p>Address: {address}</p>
                    { account && account.balance && (
                        <p>Balance: {account.balance?.toString()} TRX</p>
                    )}
                    { account && account.energy && (
                        <p>Energy: {account.energy?.toString()}</p>
                    )}
                    { account && account.balance && (
                        <p>Bandwidth: {account.bandwidth?.toString()}</p>
                    )}
                </div>
            </div>

            {loading ? (
                <div className="loading-container">
                    <div className="loader"></div>
                    <p>Loading tokens...</p>
                </div>
            ) : error ? (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="tokens-container">
                    <h3>Your Tokens ({tokens.length})</h3>
                    {tokens.length > 0 ? (
                        <TokenTransfer
                            account={account}
                            tokens={tokens}
                            defaultTokenAddress={tokens[0].address}
                        />
                    ) : (
                        <div className="no-tokens-message">
                            <p>No tokens found in your wallet</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TokenView;
