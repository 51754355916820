// @ts-ignore

import TronWeb from 'tronweb';
const isNileTestnet= process.env.REACT_APP_NETWORK === 'Nile';
const isShastaTestnet = process.env.REACT_APP_NETWORK === 'Shasta';

const fullHost = isNileTestnet 
  ? process.env.REACT_APP_TRON_NILE_FULL_HOST 
  : isShastaTestnet 
    ? process.env.REACT_APP_TRON_SHASTA_FULL_HOST 
    : process.env.REACT_APP_TRON_NODE_FULL_HOST;

const apiKey = isNileTestnet 
  ? process.env.REACT_APP_TRON_NILE_TOKEN 
  : isShastaTestnet 
    ? process.env.REACT_APP_TRON_SHASTA_TOKEN 
    : process.env.REACT_APP_TRON_NODE_TOKEN;

export const tronWeb: any = new TronWeb({
  fullHost,
  headers: { 'TRON-PRO-API-KEY': apiKey },
});
(window as any).tronWeb1 = tronWeb;
