import { tronWeb } from '../tronweb';

export async function checkTransactionWithTimeout(txId: string, maxDuration: number = 20000): Promise<any> {
    const startTime = Date.now();
    
    while (true) {
      try {
        // Check if we've exceeded the timeout
        if (Date.now() - startTime > maxDuration) {
          throw new Error(`Transaction check timed out after ${maxDuration/1000} seconds`);
        }
  
        // Attempt to get the transaction
        const transaction = await tronWeb.trx.getTransaction(txId);
        
        if (transaction) {
          // If transaction is found, verify its status
          if (transaction.ret && transaction.ret[0].contractRet === 'SUCCESS') {
            console.log('checkTransactionWithTimeout: Transaction successful:', transaction);

            // Check if at least 1 confirmation is received
            if (transaction.raw_data && transaction.raw_data.ref_block_bytes) {
              const block_bytes = transaction.raw_data.ref_block_bytes;
              const cuurentBlock = await tronWeb.trx.getCurrentBlock();
              
              const currentBlockNumber = cuurentBlock.block_header.raw_data.number;
              const blockTransactionNumber = parseInt(block_bytes, 16); 
              console.log('checkTransactionWithTimeout: Current block number:', currentBlockNumber);
              console.log('checkTransactionWithTimeout: Block transaction number:', blockTransactionNumber);
              console.log('checkTransactionWithTimeout: Current block:', cuurentBlock);
              console.log('checkTransactionWithTimeout: Block bytes:', block_bytes);
              
              // Check how many blocks have been confirmed
              if (currentBlockNumber - blockTransactionNumber >= 1) {
                console.log('checkTransactionWithTimeout: Transaction confirmed:', transaction);
                return transaction;
              } else {
                console.log('checkTransactionWithTimeout: Transaction not yet confirmed:', transaction);
              }

            } else {
              console.log('checkTransactionWithTimeout: Transaction not yet confirmed:', transaction);
            }
          } else if (transaction.ret && transaction.ret[0].contractRet === 'FAILED') {
            throw new Error('Transaction failed');
          }
        }
  
        // If we reach here, transaction is still pending
        // Wait for a short interval before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));
        
      } catch (error) {
        // If this is our timeout error, rethrow it
        if (error.message?.includes('timed out')) {
          throw error;
        }
        
        // For other errors, log and continue if we still have time
        console.error('Error checking transaction:', error);
        if (Date.now() - startTime > maxDuration) {
          throw new Error(`Transaction check timed out after ${maxDuration/1000} seconds`);
        }
      }
    }
  }
  
