// src/components/Hackathons/Hackathons.tsx
import React from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LaunchIcon from '@mui/icons-material/Launch';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import '../../../styles/brand/landing/Hackathons.css';

import tronIMG from '../../../assets/tron.png';
import bitTorent from '../../../assets/bitTorent.png';

interface HackathonData {
  logo: string;
  name: string;
  position: string;
  prize: string;
  description: string;
  projectLink: string;
  hackathonLink: string;
  date: string;
  tags: string[];
}

const Hackathons: React.FC = () => {
  const hackathons: HackathonData[] = [
    {
        logo: tronIMG,
        name: 'HackaTRON Season 4',
        position: 'Project Engagement Award',
        description: 'Starting idea of OnChainVision the first mobile dApp on TRON.',
        tags: ['DeFi', 'Mobile', 'Engagement'],
        prize: '3,000',
        projectLink: 'https://forum.trondao.org/t/onchain-vision-open-your-eyes-on-chain-world/17293',
        hackathonLink: 'https://dorahacks.io/hackathon/hackatrons4/buidl',
        date: 'August 2023'
    },
    {
        logo: tronIMG,
        name: 'HackaTRON Season 6',
        position: '4th Place Community Choice',
        description: 'OnChainVision was voted as one of the top projects by the TRON community.',
        tags: ['DeFi', 'Mobile', 'Charts'],
        prize: '4,000',
        projectLink: 'https://forum.trondao.org/t/onchainvision-open-your-eyes-on-chain-worlds/24340',
        hackathonLink: 'https://trons6.devpost.com/',
        date: 'August 2023'
    },
    {
        logo: bitTorent,
        name: 'HackaTRON Season 6',
        position: '5th Place Judges Choice',
        description: 'BttTipBot was recognized for its innovative use of BitTorrent technology.',
        tags: ['Bot', 'Telegram', 'Tips'],
        prize: '6,000',
        projectLink: 'https://forum.trondao.org/t/btttipbot-tipping-is-mandatory-sir/23643',
        hackathonLink: 'https://trons6.devpost.com/',
        date: 'Mar 2024'
    },
    {
        logo: bitTorent,
        name: 'HackaTRON Season 6',
        position: '2th Place Community Choice',
        description: 'BttTipBot was recognized by the community for the potential it provides.',
        tags: ['Bot', 'Telegram', 'Tips'],
        prize: '6,000',
        projectLink: 'https://forum.trondao.org/t/btttipbot-tipping-is-mandatory-sir/23643',
        hackathonLink: 'https://trons6.devpost.com/',
        date: 'Mar 2024'
    },
    {
        logo: tronIMG,
        name: 'HackaTRON Season 7',
        position: '5th Place Judges Choice',
        description: 'OnChainVision was voted by the judge for the potentiol it has.',
        tags: ['DeFi', 'Mobile', 'Charts'],
        prize: '6,000',
        projectLink: 'https://forum.trondao.org/t/onchainvision-the-journey-continues/27218',
        hackathonLink: 'https://hackatron7.devpost.com/',
        date: 'Nov 2024'
    },
    {
        logo: tronIMG,
        name: 'HackaTRON Season 7',
        position: 'Bonus Prize Winner',
        description: 'Tron Community Energy Bot was recognized for its innovative tech.',
        tags: ['Bot', 'Telegram', 'Energy'],
        prize: '5,000',
        projectLink: 'https://forum.trondao.org/t/tron-community-energy-bot/26811',
        hackathonLink: 'https://hackatron7.devpost.com/',
        date: 'Nov 2024'
    }
  ];

  return (
    <section className="hackathon-section">
      <div className="hackathon-container">
        <div className="section-header">
          <h2 className="section-title">Hackathon Achievements</h2>
          <p className="section-subtitle">
            Our innovative solutions have been recognized and awarded at major blockchain hackathons with over $30,000 in prizes.
          </p>
        </div>

        <div className="hackathon-grid">
          {hackathons.map((hackathon, index) => (
            <div key={index} className="hackathon-card">
              <div className="hackathon-header">
                <div className="hackathon-logo">
                  <img src={hackathon.logo} alt={`${hackathon.name} logo`} />
                </div>
                <div className="hackathon-date">{hackathon.date}</div>
              </div>

              <h3 className="hackathon-name">{hackathon.name}</h3>
              
              <div className="hackathon-achievements">
                <div className="achievement-item">
                  <EmojiEventsIcon className="achievement-icon trophy-icon" />
                  <span>{hackathon.position}</span>
                </div>
                <div className="achievement-item">
                  <AttachMoneyIcon className="achievement-icon prize-icon" />
                  <span>{hackathon.prize}</span>
                </div>
              </div>

              <p className="hackathon-description">{hackathon.description}</p>
              
              <div className="hackathon-tags">
                {hackathon.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="hackathon-tag">
                    {tag}
                  </span>
                ))}
              </div>

              <div className="hackathon-links">
                <a 
                  href={hackathon.projectLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hackathon-link project-link"
                >
                  View Project <LaunchIcon className="link-icon" />
                </a>
                <a 
                  href={hackathon.hackathonLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hackathon-link hackathon-page-link"
                >
                  Hackathon Page <LaunchIcon className="link-icon" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hackathons;
