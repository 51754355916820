// src/pages/About/About.tsx
import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import '../../styles/brand/About.css';

const About: React.FC = () => {
  return (
    <Container className="about-container">
      <Paper className="about-paper">
        <Box className="about-content">
          <Typography variant="h4" component="h1" className="about-title">
            About Our Platform
          </Typography>

          <Box className="about-description">
            <Typography variant="body1" paragraph>
              Welcome to our Web3 platform, designed specifically for the TRON network. 
              We provide a seamless and secure way to manage and transfer your TRON-based tokens.
            </Typography>

            <Typography variant="body1" paragraph>
              Built on the powerful TRON blockchain, our platform enables fast, efficient, 
              and cost-effective transactions while maintaining the highest standards of security 
              and user experience.
            </Typography>
          </Box>

          <Grid container spacing={4} className="features-grid">
            <Grid item xs={12} md={4}>
              <Box className="feature-box">
                <SecurityIcon className="feature-icon" />
                <Typography variant="h6" className="feature-title">
                  Secure Transactions
                </Typography>
                <Typography variant="body2">
                  Your security is our priority. All transactions are protected by 
                  state-of-the-art blockchain technology.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className="feature-box">
                <SpeedIcon className="feature-icon" />
                <Typography variant="h6" className="feature-title">
                  Lightning Fast
                </Typography>
                <Typography variant="body2">
                  Experience near-instant transactions on the TRON network with 
                  minimal fees.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className="feature-box">
                <AccountBalanceWalletIcon className="feature-icon" />
                <Typography variant="h6" className="feature-title">
                  Easy Management
                </Typography>
                <Typography variant="body2">
                  Manage all your TRON tokens in one place with our intuitive 
                  interface.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="about-network">
            <Typography variant="h5" gutterBottom>
              Why TRON Network?
            </Typography>
            <Typography variant="body1" paragraph>
              TRON is one of the largest blockchain-based operating systems in the world, 
              offering high throughput, high scalability, and high availability for all 
              decentralized applications in the TRON ecosystem.
            </Typography>
            <Typography variant="body1">
              Key advantages of using TRON:
            </Typography>
            <ul className="tron-benefits">
              <li>High transaction speed (2000+ TPS)</li>
              <li>Low transaction costs</li>
              <li>Smart contract support</li>
              <li>Large and active ecosystem</li>
              <li>Strong developer community</li>
            </ul>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default About;
