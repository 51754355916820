// api/client.ts
import axios, { AxiosInstance } from 'axios';
import { Currency, Exchange, ServerToken, Pair, TronLinkAction } from './types';

class ApiClient {
  private client: AxiosInstance;

  constructor(baseURL: string, token?: string) {
    this.client = axios.create({
      baseURL,
      headers: token ? {
        Authorization: `Token ${token}`
      } : {}
    });
  }

  // DEX Related endpoints
  async getCurrencies(): Promise<Currency[]> {
    const { data } = await this.client.get('/api/dex/currency/');
    return data;
  }

  async getCurrency(id: number): Promise<Currency> {
    const { data } = await this.client.get(`/api/dex/currency/${id}/`);
    return data;
  }

  async getExchanges(networkId?: number): Promise<Exchange[]> {
    const params = networkId ? { network: networkId } : undefined;
    const { data } = await this.client.get('/api/dex/exchanges/', { params });
    return data;
  }

  async getTokens(params?: {
    address?: string;
    exchange?: number;
    network?: number;
    symbol?: number;
    forceUpdate?: number;
  }): Promise<ServerToken[]> {
    const { data } = await this.client.get('/api/dex/token/', { params });
    return data;
  }

  async getPairs(params?: {
    address?: string;
    exchange?: number;
    network?: number;
    symbol?: number;
    token0?: string;
    token1?: string;
    forceUpdate?: number;
  }): Promise<Pair[]> {
    const { data } = await this.client.get('/api/dex/pair/', { params });
    return data;
  }

  async getTronLinkAction(id: string) : Promise<TronLinkAction[]> {
    const { data } = await this.client.get(`/api/ums/tronlinkactions`, { params: { id } });
    return data;
  }

  // Add other endpoint methods as needed
}

// Create and export a singleton instance
export const apiClient = new ApiClient(
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000',
  process.env.REACT_APP_API_TOKEN || 'EMPTY-TOKEN'
);
