// src/pages/Landing/Landing.tsx
import React, { useState } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Button,
  IconButton,
  Tooltip,
  Chip 
} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom';
import '../../styles/brand/Landing.css';

import HackathonSection from './landing/Hackathons';
import Hero from './landing/Hero';
import Achievements from './landing/Achivements';
import Product from './landing/Products';

const Landing: React.FC = () => {

  return (
    <Box className="landing-page">
      <Hero />
      <Achievements />
      <Product />
      <HackathonSection />
    </Box>
  );
};

export default Landing;
