// src/components/layout/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Transfer from '@mui/icons-material/TransferWithinAStation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GitHubIcon from '@mui/icons-material/GitHub';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { FaDiscord as DiscordIcon } from "react-icons/fa";

import '../../styles/Footer.css';
import { Android, AndroidTwoTone, Reddit } from '@mui/icons-material';

interface FooterLink {
  title: string;
  path: string;
  icon: React.ReactNode;
  external?: boolean;
}

interface FooterSection {
  title: string;
  links: FooterLink[];
}

const footerSections: FooterSection[] = [
  {
    title: 'About',
    links: [
      { title: 'About Us', path: '/about', icon: <InfoIcon /> },
      { title: 'Contact', path: '/contact', icon: <ContactSupportIcon /> },
      { title: 'Terms', path: '/terms', icon: <GavelIcon /> },
      { title: 'Privacy', path: '/privacy', icon: <SecurityIcon /> },
    ],
  },
  {
    title: 'Products',
    links: [
      { 
        title: 'Mobile dApp', 
        path: 'https://play.google.com/store/apps/details?id=com.onchainvision.onchainvision', 
        icon: <AndroidTwoTone />,
        external: true 
      },
      { title: 'Transfer', path: '/transfer', icon: <Transfer /> },
      { title: 'Swap', path: '/swap', icon: <SwapHorizIcon /> },
      { title: 'Analytics', path: '/analytics', icon: <QueryStatsIcon /> },
    ],
  },
  {
    title: 'Support',
    links: [
      { 
        title: 'Documentation', 
        path: 'https://forum.trondao.org/t/onchainvision-the-journey-continues/27218', 
        icon: <MenuBookIcon />,
        external: true 
      },
      { 
        title: 'GitHub', 
        path: 'https://github.com/onchainvision', 
        icon: <GitHubIcon />,
        external: true 
      },
    ],
  },
];

const socialLinks = [
  { 
    title: 'Telegram', 
    path: 'https://t.me/onchainvision', 
    icon: <TelegramIcon />,
    color: '#229ED9' 
  },
  { 
    title: 'Discord', 
    path: 'https://discord.gg/pNrumA4Sk6', 
    icon: <DiscordIcon />,
    color: '#5865F2' 
  },
  { 
    title: 'X (Twitter)', 
    path: 'https://x.com/onchainvision', 
    icon: <XIcon />,
    color: '#000000' 
  },
  { 
    title: 'Android', 
    path: 'https://play.google.com/store/apps/details?id=com.onchainvision.onchainvision', 
    icon: <Android />,
    color: '#229ED9' 
  },
  { 
    title: 'Reddit', 
    path: 'https://www.reddit.com/r/OnChainVision/', 
    icon: <Reddit />,
    color: '#229ED9' 
  },
];

const FooterLink: React.FC<FooterLink> = ({ title, path, icon, external }) => {
  const content = (
    <Box className="footer-link">
      {icon}
      <Typography variant="body2">{title}</Typography>
    </Box>
  );

  return external ? (
    <a 
      href={path} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="footer-link-wrapper"
    >
      {content}
    </a>
  ) : (
    <Link to={path} className="footer-link-wrapper">
      {content}
    </Link>
  );
};

const Footer: React.FC = () => {
  return (
    <Box component="footer" className="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4} className="footer-grid">
          {footerSections.map((section, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box className="footer-section">
                <Typography variant="h6" className="footer-section-title">
                  {section.title}
                </Typography>
                <Box className="footer-links">
                  {section.links.map((link, linkIndex) => (
                    <FooterLink key={linkIndex} {...link} />
                  ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box className="footer-social">
          <Typography variant="h6" className="social-title">
            Join Our Community
          </Typography>
          <Box className="social-links">
            {socialLinks.map((social, index) => (
              <Tooltip title={social.title} key={index}>
                <IconButton
                  href={social.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-button"
                  sx={{ 
                    '&:hover': { 
                      color: social.color,
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  {social.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Box>

        <Box className="footer-bottom">
          <Typography variant="body2" color="textSecondary">
            © {new Date().getFullYear()} OnChainVision. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
