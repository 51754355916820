

export const convertFromUnit = (value: string, decimals: number) => {
    const unit = 10 ** decimals;
    return parseFloat(value) / unit;
};

export const convertToUnit = (value: string, decimals: number) => {
    const unit = 10 ** decimals;
    return parseFloat(value) * unit;
};

