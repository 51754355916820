// theme.ts
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
  },
  components: {
    // Custom Modal styles
    MuiModal: {
      styleOverrides: {
        root: {
          '& .modal-content': {
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            padding: '24px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
  },
});

export default theme;
